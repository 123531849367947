import axios from "axios";
import { toast } from 'react-toastify/dist/react-toastify.esm';
// import { getCustomers, createCustomer } from "../api/StripeApi";
import {
  deleteStoreManagement,
  getNotificationDetail,
  getUserDetail,
  sendAppNotification,
  fetchWexxleFriendList,
  createMediaplayLog,
  updateMediaplayLog,
  getMediaplayLogById,
} from "../api/GraphqlApi";
import { send_push_notification } from "./sendWebPush";
import * as geolib from "geolib";
import { fetchStoreCapacity, tagAnalticsCreateUser } from "../api/GraphqlApi";
import $ from 'jquery/dist/jquery';
import { API } from "aws-amplify";
import * as graphqlQuery from '../graphql/queries'
import _ from 'underscore';
import moment from "moment";
import * as UAParser from 'ua-parser-js';
const uaParser = new UAParser.UAParser()


const fetchDeviceDetail = () => {
  try {
    if (window.navigator.userAgent) uaParser.setUA(window.navigator.userAgent);
    return {
      os: uaParser.getOS(),
      browser: uaParser.getBrowser(),
      device: uaParser.getDevice(),
    };
  } catch (err) {
    return null;
  }
}

export function isMobile() {
  const minWidth = 768; // Minimum width for desktop devices
  return window.innerWidth < minWidth || window.screen.width < minWidth
}


export const listsorted = (array, param) => {
  if (Array.isArray(array) && array.length) {
    return _.sortBy(array, param)
  }
}

export const getIdFromUrl = () => {
  var str = window.location.href;
  var indices = [];
  for (var i = 0; i < str.length; i++) {
    if (str[i] === "/") indices.push(i);
  }
  let id = str.substring(indices[3] + 1, indices[4]);
  return id;
};

export const getIpv4 = async () => {
  try {
    let { data } = await axios({
      url: "https://ipapi.co/json/",
      method: "get",
    });
    let { ip } = data;
    return ip;
  } catch (error) {
    console.log("Error is " + error);
  }
};

export const countryCode = async () => {
  try {
    let { data } = await axios({
      url: "https://ipapi.co/json/",
      method: "get",
    });
    let { country_code } = data;
    return country_code.toLowerCase();
  } catch (error) {
    console.log("Error is " + error);
  }
};

export const removeClass = () => {
  let InputClass = document.getElementsByClassName("otpInput");
  InputClass[0].classList.remove("focusblueclr");
  InputClass[1].classList.remove("focusblueclr");
  InputClass[2].classList.remove("focusblueclr");
  InputClass[3].classList.remove("focusblueclr");
};

export const fetchUrlRoute = () => {
  let search = window.location.href;
  var searchurl = "";
  if (["localhost", "127.0.0.1", ""].includes(window.location.hostname)) {
    searchurl =
      window.location.protocol === "http:"
        ? search.slice(22)
        : search.slice(23);
  } else {
    searchurl = search.slice(22);
  }
  return searchurl;
};

// clear browser cache
// export const emptyCache = () => {
//   if ('caches' in window) {
//     caches.keys().then((names) => {
//       names.forEach(name => {
//         caches.delete(name);
//       })
//     });
//     window.location.reload(true);
//   }
// }

// export const fetchAndCreateCustomer = async (email, name) => {
//   if (!email) {
//     toast.error("Email Not Found!");
//     return;
//   }
//   let res = await getCustomers(email);
//   if (res) var { data, status } = res;
//   if (status === 200) {
//     if (data.data && !data.data.length) {
//       let customer = await createCustomer(name, email);
//       if (customer && customer.status === 200) {
//         if (customer.data && customer.data.id) {
//           window.localStorage.setItem("customer_id", customer.data.id);
//         } else {
//           toast.error("Something Went Wrong While Creating Customer Id!");
//         }
//       }
//     } else if (data && data.data.length) {
//       if (data.data[0].id) {
//         window.localStorage.setItem("customer_id", data.data[0].id);
//       } else {
//         toast.error("Something Went Wrong While Fetching Customer Id!");
//       }
//     }
//   }
// };


export const svgImage = (type, color, size) => {
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(getSvgByTagType(type, color, size));
}

export const getSvgByTagType = (type, color, size) => {
  switch (type) {
    case "post":
    case "media":
      return `<svg xmlns="http://www.w3.org/2000/svg" width='${size * 20}' height='${size * 20}' viewBox="0 0 39.851 39.851"><g transform="translate(-197 -112)"><g transform="translate(197 122.004)"><g transform="translate(0 0)"><path d="M38.683,128.533H1.167A1.168,1.168,0,0,0,0,129.7v27.511a1.168,1.168,0,0,0,1.167,1.168H38.683a1.168,1.168,0,0,0,1.167-1.168V129.7A1.168,1.168,0,0,0,38.683,128.533Zm-12.17,15.895-9.379,6.253a1.167,1.167,0,0,1-1.815-.971V137.2a1.167,1.167,0,0,1,1.815-.971l9.379,6.253a1.167,1.167,0,0,1,0,1.943Z" transform="translate(0 -128.533)" fill='${color}'/></g></g><g transform="translate(200.752 117.002)"><g transform="translate(0 0)"><path d="M79.381,64.268H49.368a1.167,1.167,0,1,0,0,2.335H79.381a1.167,1.167,0,0,0,0-2.335Z" transform="translate(-48.201 -64.268)" fill='${color}'/></g></g><g transform="translate(204.503 112)"><g transform="translate(0)"><path d="M120.077,0H97.568a1.167,1.167,0,1,0,0,2.335h22.509a1.167,1.167,0,0,0,0-2.335Z" transform="translate(-96.4)" fill='${color}'/></g></g></g></svg>`
    case "memory":
      return `<svg xmlns="http://www.w3.org/2000/svg" width='${size * 20}' height='${size * 20}' viewBox="0 0 39.851 39.851"><path d="M55.681,0a16.038,16.038,0,0,0-7.025,1.593,16.571,16.571,0,0,0-9.515,14.951v2.643l-3.275,5.976a3.026,3.026,0,0,0-.366,1.4,2.261,2.261,0,0,0,.314,1.175A2.23,2.23,0,0,0,37.8,28.773h1.345v5.808a3.629,3.629,0,0,0,3.615,3.634l.032,0,4.44-.679v4.28a.706.706,0,0,0,.706.707H60.657a6.2,6.2,0,0,0,6.2-6.2v-7.68A16.535,16.535,0,0,0,55.681,0ZM54.52,6.229a1.244,1.244,0,1,1,2.488,0V7.265a1.244,1.244,0,1,1-2.488,0ZM46.772,17.441H45.737a1.246,1.246,0,0,1,0-2.491h1.035a1.246,1.246,0,0,1,0,2.491ZM48.2,10.966l-.732-.732a1.245,1.245,0,1,1,1.759-1.762l.732.732A1.245,1.245,0,1,1,48.2,10.966ZM59.37,21.22,58.99,24.4a3.241,3.241,0,0,1-6.437,0l-.381-3.182a6.126,6.126,0,0,1,3.6-11.079H55.8A6.126,6.126,0,0,1,59.37,21.22Zm3.079-9.89a1.246,1.246,0,0,1-.88-2.127l.732-.732a1.245,1.245,0,0,1,1.759,1.762l-.732.732A1.238,1.238,0,0,1,62.449,11.33Zm3.331,6.111H64.744a1.246,1.246,0,0,1,0-2.491H65.78a1.246,1.246,0,0,1,0,2.491Z" transform="translate(-35.5)" fill='${color}'/></svg>`
    case "event":
      return `<svg xmlns="http://www.w3.org/2000/svg" width='${size * 20}' height='${size * 20}' viewBox="0 0 39.851 39.851"><path d="M37,42H5a5.006,5.006,0,0,1-5-5V10A5.006,5.006,0,0,1,5,5H7V3a3,3,0,1,1,6,0V5H29V3a3,3,0,0,1,6,0V5h2a5.006,5.006,0,0,1,5,5V37A5.006,5.006,0,0,1,37,42ZM6,15a1,1,0,0,0-1,1V36a1,1,0,0,0,1,1H36a1,1,0,0,0,1-1V16a1,1,0,0,0-1-1Z" transform="translate(0 0)" fill='${color}'/><path d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2" transform="translate(10 20)" fill='${color}'/><path d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2" transform="translate(10 28)" fill='${color}'/><path d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2" transform="translate(19 20)" fill='${color}'/><path d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2" transform="translate(19 28)" fill='${color}'/><path d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2" transform="translate(28 20)" fill='${color}'/><path d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2" transform="translate(28 28)" fill='${color}'/></svg>`
    case "task":
      return `<svg xmlns="http://www.w3.org/2000/svg" width='${size * 20}' height='${size * 20}' viewBox="0 0 39.851 39.851">
      <g id="Group_11876" data-name="Group 11876" transform="translate(12178.586 7513)">
        <g id="Group_11858" data-name="Group 11858" transform="translate(-12178.586 -7513)">
          <g id="Group_11857" data-name="Group 11857" transform="translate(0 0)">
            <path id="Path_31649" data-name="Path 31649" d="M26.147,7.141V2.5H14.831a1.417,1.417,0,0,0-1.417,1.417V26.583A1.417,1.417,0,0,0,14.831,28H31.638a1.417,1.417,0,0,0,1.417-1.417V9.408H28.414A2.27,2.27,0,0,1,26.147,7.141ZM29.375,22.9H17.095a.708.708,0,0,1,0-1.417h12.28a.708.708,0,0,1,0,1.417Zm0-4.627H17.095a.708.708,0,0,1,0-1.417h12.28a.708.708,0,0,1,0,1.417Zm.708-5.338a.709.709,0,0,1-.708.708H17.095a.708.708,0,0,1,0-1.417h12.28A.709.709,0,0,1,30.083,12.935Z" transform="translate(-13.414 -2.5)" fill='${color}'/>
            <path id="Path_31650" data-name="Path 31650" d="M65.434,8.558h4.641L64.018,2.5V7.141A1.417,1.417,0,0,0,65.434,8.558Z" transform="translate(-50.435 -2.5)" fill='${color}'/>
          </g>
        </g>
      </g>
    </svg>
    `
    case "asset":
      return `<svg xmlns="http://www.w3.org/2000/svg" width='${size * 20}' height='${size * 20}' viewBox="0 0 39.851 39.851"><g transform="translate(-116.652 0)"><path d="M134.1,19.906a52.756,52.756,0,0,0-5.358-2.426,12.029,12.029,0,0,1-2.941-1.647,2.558,2.558,0,0,1,.634-4.552,5.163,5.163,0,0,1,1.8-.371A13.671,13.671,0,0,1,135,12.249c1.068.516,1.421.353,1.783-.76.38-1.177.7-2.371,1.05-3.557a1.242,1.242,0,0,0-.805-1.656A17.209,17.209,0,0,0,132.76,5c-1.937-.3-1.937-.308-1.946-2.254C130.805,0,130.805,0,128.054,0c-.4,0-.8-.009-1.195,0-1.285.036-1.5.262-1.539,1.557-.018.579,0,1.158-.009,1.747-.009,1.72-.018,1.692-1.665,2.29C119.664,7.045,117.2,9.76,116.94,14.1c-.235,3.846,1.774,6.444,4.933,8.336a54.529,54.529,0,0,0,6.163,2.769,9.155,9.155,0,0,1,2.245,1.321,3.077,3.077,0,0,1-.733,5.394,7.382,7.382,0,0,1-3.955.516,17.653,17.653,0,0,1-6.028-1.8c-1.113-.579-1.439-.425-1.819.778-.326,1.041-.615,2.091-.905,3.141-.389,1.412-.244,1.747,1.1,2.407a19.246,19.246,0,0,0,5.43,1.557c1.466.235,1.511.3,1.53,1.819.009.688.009,1.385.018,2.073a1.231,1.231,0,0,0,1.321,1.394c1.014.018,2.036.018,3.05-.009a1.16,1.16,0,0,0,1.258-1.312c0-.941.045-1.892.009-2.833a1.5,1.5,0,0,1,1.294-1.7,10.228,10.228,0,0,0,5.331-3.421A9.619,9.619,0,0,0,134.1,19.906Z" transform="translate(0 0)" fill='${color}'/></g></svg>`
    case "quick":
      return `<svg xmlns="http://www.w3.org/2000/svg" width='${size * 20}' height='${size * 20}' viewBox="0 0 20.002 23.999"><g transform="translate(-99 -690.002)"><path d="M19926.393,9829.655a10,10,0,1,1,5.217,0L19929,9834Z" transform="translate(-19820 -9120)" fill='${color}'/><g transform="translate(104.846 696.222)"><g transform="translate(2.838 1.101)"><path d="M0,0H.507V4.057a.254.254,0,1,1-.507,0Z" transform="translate(1.268 2.536)" fill='#fff'/><path d="M3.043,1.521A1.521,1.521,0,1,1,1.521,0,1.521,1.521,0,0,1,3.043,1.521Z" fill='#fff'/><path d="M2.029,1.014A1.014,1.014,0,1,1,1.014,0,1.014,1.014,0,0,1,2.029,1.014Z" transform="translate(0.507 0.507)" fill='#fff'/></g></g></g></svg>`
    case "ecommerce":
      return `<svg xmlns="http://www.w3.org/2000/svg" width='${size * 20}' height='${size * 20}' viewBox="0 0 39.851 39.851"><g transform="translate(-0.837 -0.33)"><path d="M12.9,7l-.5-2.47A5.493,5.493,0,0,0,6.993,0H2.643A2.6,2.6,0,0,0,.013,2.24,2.506,2.506,0,0,0,2.5,5h4.49a.514.514,0,0,1,.5.47l4.29,21.21a5,5,0,0,0,5.27,4l18.64-1.38a5,5,0,0,0,4.59-4.31l1.67-12.31A5,5,0,0,0,37,7H12.9" transform="translate(0.837 0.33)" fill='${color}'/><path d="M8,4A4,4,0,1,1,4,0,4,4,0,0,1,8,4" transform="translate(11.84 34.33)" fill='${color}'/><path d="M8,4A4,4,0,1,1,4,0,4,4,0,0,1,8,4" transform="translate(29.84 34.33)" fill='${color}'/></g></svg>`
    default:
      return ``
  }
};

// Aws Amplify Push notification
export const pushNotification = async (notificationId) => {
  let response = await getNotificationDetail(notificationId);
  if (response && response.statusCode === 200) {
    if (response.body && response.body.length) {
      let { title, message, is_active } = response.body[0];
      if (is_active === 1)
        send_push_notification(localStorage.getItem("id"), title, message);
    }
  }
};

export const resetGoogleRecaptcha = (recaptchaId) => {
  window.grecaptcha.reset();
  $(`#${recaptchaId}`).remove();
};

// "sitekey --- 6LdWlYwhAAAAAMX8F1rCpxSRESLTZ5BR7pg2zWZm"
// "secretkey --- 6LdWlYwhAAAAAPzZbwOFyyioXXuysgwcbHwrL1Uq"

export const validateRecaptcha = () => {
  var response = window.grecaptcha.getResponse();
  if (response.length) return true;
  else return false;
};

export const verifiedOrNot = async (type) => {
  if (type === "ecommerce") {
    let isVerified = await getUserDetail(
      "user_detail",
      localStorage.getItem("id")
    );
    let { body, statusCode } = isVerified;
    if (statusCode && statusCode === 200) {
      if (body && body.length) {
        let { verified } = body[0];
        return verified;
      }
    }
  }
};

export const getRandomValue = (arrayList, suffleNo) => {
  var latestItems = [];
  if (arrayList.length < suffleNo) return arrayList;
  for (var i = 0; i < suffleNo; i++) {
    var idx = Math.floor(Math.random() * arrayList.length);
    latestItems.push(arrayList[idx]);
    arrayList.splice(idx, 1);
  }
  return latestItems;
};

export const userActionLog = () => { };

export const deviceOs = () => {
  // var OSName = "Unknown OS";
  // if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
  // if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
  // if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
  // if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";
  // return OSName;
  let response = fetchDeviceDetail()
  return response.os?.name
};

/** Get Browser with version */
// export const getBrowserName = () => {
//   var N = navigator.appName,
//     ua = navigator.userAgent,
//     tem;
//   var M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
//   if (M && (tem = ua.match(/version\/([\d]+)/i)) !== null) M[2] = tem[1];
//   M = M ? [M[1], M[2]] : [N, navigator.appVersion, "-?"];
//   return M;
// };


export function getBrowser() {
  return (function (agent) {
    switch (true) {
      case agent.indexOf("edge") > -1: return "MS Edge";
      case agent.indexOf("edg/") > -1: return "Edge";
      case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
      case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
      case agent.indexOf("trident") > -1: return "MS IE";
      case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
      case agent.indexOf("safari") > -1: return "Safari";
      default: return "other";
    }
  })(window.navigator.userAgent.toLowerCase());
}


/**Get device name*/
export const deviceType = () => {
  var deviceAgent = navigator.userAgent.toLowerCase();
  var deviceName = "desktop";
  if (deviceAgent.match(/(android)/i)) deviceName = "android phone";
  else if (deviceAgent.match(/(iemobile)/i)) deviceName = "window phone";
  else if (deviceAgent.match(/iphone/i)) deviceName = "iphone";
  else if (deviceAgent.match(/ipad/i)) deviceName = "ipad";
  else if (deviceAgent.match(/ipod/i)) deviceName = "ipod";
  else if (deviceAgent.match(/blackberry/i)) deviceName = "blackberry";
  else if (deviceAgent.match(/bada/i)) deviceName = "bada";
  return deviceName;
};

export const proximityDistanceBetweentwoPlace = (coords1, coords2) => {
  const { lat: lat1, lon: lon1 } = coords1;
  const { lat: lat2, lon: lon2 } = coords2;
  const degToRad = (x) => (x * Math.PI) / 180;
  const R = 6371;
  const halfDLat = degToRad(lat2 - lat1) / 2;
  const halfDLon = degToRad(lon2 - lon1) / 2;
  const a =
    Math.sin(halfDLat) * Math.sin(halfDLat) +
    Math.cos(degToRad(lat1)) *
    Math.cos(degToRad(lat2)) *
    Math.sin(halfDLon) *
    Math.sin(halfDLon);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c) / 1.609;
};

export const getBoundsOfDistance = (lat, lng) => {
  if (!lat || !lng) return;
  var bounds = geolib.getBoundsOfDistance(
    { latitude: lat, longitude: lng },
    (localStorage.getItem("default_distance") || 5) * 1609.344
  );
  return {
    maxLat: parseFloat(bounds[1].latitude),
    maxLon: parseFloat(bounds[1].longitude),
    minLat: parseFloat(bounds[0].latitude),
    minLon: parseFloat(bounds[0].longitude),
  };
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const redirectToPeopleProfile = (id, history) => {
  history.push({
    pathname: `/search-people-profile/${id}`,
    state: { searchid: id },
  });
};

export const fetchWexxleCapacity = async (filesize) => {
  let capacity = localStorage.getItem("wexxlestorage");
  capacity = capacity || 3
  if (capacity) {
    var currentcapacity = capacity * 1048576;
  } // In KB
  let response = await fetchStoreCapacity();
  let { items } = response?.data?.searchUserStorageManagements;
  let totalconsumecapacity =
    items && items.length
      ? items.map((val) => val.file_size).reduce((a, b) => a + b)
      : 0;
  if (capacity) {
    let remainingcapacity = currentcapacity - Math.floor(totalconsumecapacity / 1024);
    if (filesize / 1024 < remainingcapacity) return true;
    else {
      toast.error("Insufficient storage available");
      return false;
    }
  } else {
    toast.error("Something went wrong!");
    return false;
  }
};

export const aggregateWexxleCloud = (arr) => {
  const response = Array.from(
    arr.reduce(
      (m, { file_type, file_size }) =>
        m.set(file_type, (m.get(file_type) || 0) + file_size),
      new Map()
    ),
    ([file_type, file_size]) => ({ file_type, file_size })
  );
  return response;
};

export const fetchandDeleteById = async (key) => {
  let response = await fetchStoreCapacity();
  let { items } = response?.data?.searchUserStorageManagements;
  if (items && items.length) {
    let storageObject = items.find((val) => val.key_name === key);
    if (storageObject) await deleteStoreManagement(storageObject.id);
  }
};

//["localhost", "127.0.0.1", ""].includes(window.location.hostname)
export const tagRedirectPath = (postType, id) => {
  return postType === "memory"
    ? `memory-detail/${id}`
    : postType === "task"
      ? `task-detail/${id}`
      : postType === "event"
        ? `event-detail/${id}`
        : postType === "asset"
          ? `asset-detail/${id}`
          : postType === "ecommerce"
            ? `ecommerce-detail/${id}`
            : postType === "post"
              ? `media-detail/${id}`
              : postType === "scene"
                ? `scene-detail/${id}`
                : postType === "news"
                  ? `news-detail/${id}`
                  : " ";
};

export const isInViewport = (element) => {
  if (element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
};

export const sendWexxleNotification = async (
  opponent_user_id,
  operation_name,
  template_id,
  email_category,
  reactor_name,
  sender_name,
  creator_name,
  tagtype,
  moduletype,
  tagid,
  commentTagId,
  diamond,
  activity
) => {
  if (opponent_user_id !== parseInt(localStorage.getItem("id"))) {
    let notiObject = {};
    notiObject["send_sms"] = true;
    notiObject["send_push"] = true;
    notiObject["send_email"] = true;
    notiObject["email_category"] = email_category;
    if (sender_name) notiObject["sender_name"] = sender_name;
    if (reactor_name) notiObject["reactor_name"] = reactor_name;
    if (creator_name) notiObject["creator_name"] = creator_name;
    if (activity)
      notiObject["tag_type"] = tagtype === "post" ? "media" : tagtype;
    if (diamond !== "")
      notiObject["duck_or_diamond"] = diamond === "1" ? "diamond" : "duck";
    notiObject["tag_link"] = ["Tag"].includes(email_category)
      ? moduletype === "tag"
        ? tagRedirectPath(tagtype, tagid)
        : tagRedirectPath(tagtype, commentTagId)
      : ["Chat"].includes(email_category)
        ? ["localhost", "127.0.0.1", ""].includes(window.location.hostname)
          ? "https://localhost:3000/chat"
          : "https://wexxle.io/chat"
        : "";
    notiObject["push_redirect_url"] = ["Tag"].includes(email_category)
      ? moduletype === "tag"
        ? tagRedirectPath(tagtype, tagid)
        : tagRedirectPath(tagtype, commentTagId)
      : ["Chat"].includes(email_category)
        ? ["localhost", "127.0.0.1", ""].includes(window.location.hostname)
          ? "https://localhost:3000/chat"
          : "https://wexxle.io/chat"
        : "";
    sendAppNotification(
      opponent_user_id,
      operation_name,
      template_id,
      notiObject,
      activity
    );
  }
};

export const fetchTaggedUser = async (user_id, tagged_user_id) => {
  if (!tagged_user_id || !tagged_user_id.length) return [];
  let taggeduser = [];
  let response = await fetchWexxleFriendList(user_id);
  if (response && response.statusCode && response.body) {
    let { body } = response;
    if (Array.isArray(body) && body.length) {
      taggeduser = body.filter((val) =>
        tagged_user_id.includes(JSON.stringify(val.friend_id))
      );
    }
  }
  return taggeduser;
};


export const pointsToDrawPolygon = (
  map,
  maps,
  address,
  miles,
  showCenterPoint = false,
  showVertexCircle = false,
  strokeColor,
  strokeOpacity,
  strokeWeight,
  fillColor,
  fillOpacity,
  vertexCircleStrokeColor,
  vertexCircleStrokeOpacity,
  vertexCircleStrokeWeight,
  vertexCircleFillColor,
  vertexCircleFillOpacity,
  fnToSetPoints
) => {
  var geocoder = new maps.Geocoder();
  geocoder.geocode(
    {
      address: address,
    },
    function (results, status) {
      if (status === maps.GeocoderStatus.OK) {
        //searchLocationsNear(results[0].geometry.location);
        var cordinate = results[0].geometry.location;
        // alert(cordinate);
        let tempCircleCodRefArray = [];
        if (showCenterPoint) {
          let centerPointRef = new maps.Circle({
            strokeColor: vertexCircleStrokeColor,
            strokeOpacity: vertexCircleStrokeOpacity,
            strokeWeight: vertexCircleStrokeWeight,
            fillColor: vertexCircleFillColor,
            fillOpacity: vertexCircleFillOpacity,
            map,
            center: new maps.LatLng(cordinate.lat(), cordinate.lng()),
            radius: miles === 1 ? 50 : miles === 5 ? 180 : 200,
          });
          tempCircleCodRefArray.push(centerPointRef);
        }
        var radius = miles;
        var latitude = cordinate.lat();
        var longitude = cordinate.lng();
        //Degrees to radians
        var d2r = Math.PI / 180;

        //  Radians to degrees
        var r2d = 180 / Math.PI;
        // Earth radius is 3,963 miles
        var cLat = (radius / 3963) * r2d;

        var cLng = cLat / Math.cos(latitude * d2r);
        //Store points in array
        var points = [];
        // alert("declare array");

        var bounds = new maps.LatLngBounds();

        // Calculate the points
        // Work around 360 points on circle
        for (var i = 0; i < 360; i += 360 / 8) {
          var theta = Math.PI * (i / 180);

          // Calculate next X point
          var circleY = longitude + cLng * Math.cos(theta);
          //console.log("CircleY:"+circleY);
          // Calculate next Y point
          var circleX = latitude + cLat * Math.sin(theta);
          //console.log("circleX:"+circleX);
          // Add point to array
          var aPoint = new maps.LatLng(circleX, circleY);
          points.push(aPoint);
          bounds.extend(aPoint);
        }

        points.push(points[0]);
        // console.log(points);
        //to complete circle

        showVertexCircle &&
          points.forEach((item) => {
            let tempCicrleRef = new maps.Circle({
              strokeColor: vertexCircleStrokeColor,
              strokeOpacity: vertexCircleStrokeOpacity,
              strokeWeight: vertexCircleStrokeWeight,
              fillColor: vertexCircleFillColor,
              fillOpacity: vertexCircleFillOpacity,
              map,
              center: new maps.LatLng(item.lat(), item.lng()),
              radius: miles === 1 ? 50 : miles === 5 ? 180 : 200,
            });
            tempCircleCodRefArray.push(tempCicrleRef);
          });

        var Polyline_Path = new maps.Polygon({
          paths: points,
          strokeColor: strokeColor,
          strokeOpacity: strokeOpacity,
          strokeWeight: strokeWeight,
          fillColor: fillColor,
          fillOpacity: fillOpacity,
        });
        Polyline_Path.setMap(map);
        fnToSetPoints &&
          fnToSetPoints((prev) => ({
            ...prev,
            map,
            maps,
            Polyline_Path,
            ...(tempCircleCodRefArray.length > 0 && {
              circleRef: tempCircleCodRefArray,
            }),
          }));
      } else {
        console.log(address + " not found");
      }
    }
  );
};

export const showLoginToast = () => {
  toast.info("Please log in to Wexxle to perform this action");
}

export const fetchRecentTag = (data) => {
  try {
    let response = API.graphql({
      query: graphqlQuery.searchTags,
      variables: {
        ...data
      }
    })
    return response;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
}


export const getUniqueDetailByIds = async (ids, uniquearray) => {
  if (!ids.length) return;
  if (uniquearray.length) {
    let uniques = [...new Set([...uniquearray, ...ids])]
    let uniqes_ids = uniques.filter(val => !uniquearray.includes(val))
    uniquearray.push(...uniqes_ids)
    if (uniqes_ids.length) return uniqes_ids;
  }
  if (!uniquearray.length) {
    uniquearray.push(...ids)
    return ids;
  }
}


export const aggregatelikedislike = (items, property) => {
  return items && items.length
    ? items.map((val) => val[property]).reduce((a, b) => a + b)
    : 0;
}
let arr = []
let arrClick = []
export const checkAdsAnalytics = async (tagId, analyticsType, is_view_only) => {
  if (analyticsType === 'total_views') {

    if (sessionStorage.getItem('tagIdV') !== null) {
      arr = JSON.parse(sessionStorage.getItem('tagIdV'))
      let sessionArr = JSON.parse(sessionStorage.getItem('tagIdV'))
      let index = -1
      sessionArr.forEach(async (i, ind) => {
        if (tagId === i.tagId) {
          index = ind
        }
      })
      if (index === -1) {
        await tagAnalticsCreateUser(tagId, is_view_only)
        let obj = {}
        obj.time = moment()
        obj.tagId = tagId
        arr.push(obj)
        let finalarr = JSON.stringify(arr)
        sessionStorage.setItem('tagIdV', finalarr)
      }
      else {
        if (moment(sessionArr[index].time).add(20, 'minutes') < moment()) {
          await tagAnalticsCreateUser(tagId, is_view_only)
          sessionArr[index].time = moment()
          let finalarr = JSON.stringify(sessionArr)
          sessionStorage.setItem('tagIdV', finalarr)
        }
      }
    }
    else {
      await tagAnalticsCreateUser(tagId, is_view_only)
      let obj = {}
      obj.time = moment()
      obj.tagId = tagId
      arr.push(obj)
      let finalarr = JSON.stringify(arr)
      sessionStorage.setItem('tagIdV', finalarr)
    }
  }
  else if (analyticsType === 'total_clicks') {

    if (sessionStorage.getItem('tagIdClick') !== null) {
      arrClick = JSON.parse(sessionStorage.getItem('tagIdClick'))
      let sessionArr = JSON.parse(sessionStorage.getItem('tagIdClick'))
      let index = -1
      sessionArr.forEach(async (i, ind) => {
        if (tagId === i.tagId) {
          index = ind
        }
      })
      if (index === -1) {
        await tagAnalticsCreateUser(tagId, is_view_only)
        let obj = {}
        obj.time = moment()
        obj.tagId = tagId
        arrClick.push(obj)
        let finalarr = JSON.stringify(arrClick)
        sessionStorage.setItem('tagIdClick', finalarr)
      }
      else {
        if (moment(sessionArr[index].time).add(20, 'minutes') < moment()) {
          await tagAnalticsCreateUser(tagId, is_view_only)
          sessionArr[index].time = moment()
          let finalarr = JSON.stringify(sessionArr)
          sessionStorage.setItem('tagIdV', finalarr)
        }
      }
    }
    else {
      await tagAnalticsCreateUser(tagId, is_view_only)
      let obj = {}
      obj.time = moment()
      obj.tagId = tagId
      arrClick.push(obj)
      let finalarr = JSON.stringify(arrClick)
      sessionStorage.setItem('tagIdClick', finalarr)
    }
  }
}

export const publicTagFilter = (tagList) => {
  if (!localStorage.getItem("lat") || !localStorage.getItem("lng")) return;
  if (Array.isArray(tagList) && tagList.length) {
    return tagList.filter(value => {
      const { visibility, latitude, longitude, public_distance } = value;
      if (['public'].includes(visibility) && public_distance) {
        let totalProximityDistance = geolib.getDistance(
          { latitude: localStorage.getItem("lat"), longitude: localStorage.getItem("lng") },
          { latitude: latitude, longitude: longitude },
          0.01
        )
        let distance = totalProximityDistance / 1609.344
        value.distance = distance;
        return distance <= public_distance
      }
      else return value
    })
  }
}


export const closeIfVideoOpen = (array) => {
  if (array && !array.length) return;
  array.forEach((_, index) => {
    let video = document.getElementById('player-' + index);
    if (video && !video.paused) {
      video.pause();
      // video.currentTime = 0;
    }
  })
}

export const handleMediaMounted = (element, info) => {
  if (element !== null && info?.currentTime !== undefined) element.currentTime = info.currentTime
};

export const playPauseMedia = async (event, mediatype, mediaTagDetail) => {
  let mediaInfo = {}
  const { ext, id } = mediatype
  const { tag_type, tag_id } = mediaTagDetail;
  const { currentTime, duration, currentSrc } = event.currentTarget || {};
  mediaInfo["duration"] = duration;
  mediaInfo["currentTime"] = currentTime;
  mediaInfo["key"] = currentSrc.substring(currentSrc.indexOf('.com/') + 5)
  if (id && currentTime !== undefined && duration !== undefined && mediatype) {
    let response = await getMediaplayLogById(id);
    if (response?.data?.getMediaPlayLogs && Object.keys(response.data.getMediaPlayLogs).length) {
      return updateMediaplayLog(id, ext, tag_id, tag_type, mediaInfo);
    }
  } else {
    return createMediaplayLog(ext, tag_id, tag_type, mediaInfo);
  }
}


export const tagClass = (item) => {
  return item === "event" ||
    item === "task" ||
    item === "spotify" ||
    item === "scene"
    ? `tagIcon success`
    : item === "post" || item === "media" || item === 'quick'
      ? `tagIcon secondary`
      : item === "ecommerce" || item === "asset" || item === "news"
        ? `tagIcon primary`
        : item === "memory" || item === "floor"
          ? `tagIcon tertiary`
          : item === "docusign"
            ? "tagIcon"
            : ""
}

export const deviceUniqueId = () => {
  const userAgent = window?.navigator?.userAgent;
  const platform = window?.navigator?.platform;
  const randomString = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);
  const deviceID = `${userAgent}-${platform}-${randomString}`;
  return deviceID;
}

export const FetchTagByIds = async (ids) => {
  let variable = {};
  variable["filter"] = {};
  variable["filter"]["id"] = {};
  variable["filter"]["id"]["match"] = ids;
  let response = await fetchRecentTag(variable)
  const { items } = response?.data?.searchTags || {};
  return items || [];
}

export const generateVideoThumbnail = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};


export const getImageUrl = (src, ext) => {
  if (ext === "mp4") return process.env.REACT_APP_MEDIA_BASE_URL +
    "/thumbnail/medium" +
    src.substring(process.env.REACT_APP_MEDIA_BASE_URL.length, src.lastIndexOf(".")) +
    ".JPEG"
  else return src;
}

export const convertArrayToCSV = (array) => {
  const header = Object.keys(array[0]).join(',');
  const rows = array.map((obj) => Object.values(obj).join(',')).join('\n');
  return header + '\n' + rows;
};

export const whetherPublicOrNot = async (id, history) => {
  const response = await API.graphql({
    query: graphqlQuery.getTag,
    variables: { id: id }
  })
  if (response?.data?.getTag?.visibility !== 'public' && !localStorage.getItem('id')) {
    localStorage.clear()
    history.push('/')
  }
}

export function copyToClipboard(text) {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      reject('Clipboard API not supported');
      return;
    }
    navigator.clipboard.writeText(text)
      .then(() => {
        resolve('Copied!');
      })
      .catch((err) => {
        reject(`Failed to copy!`);
      });
  });
}